.react-bootstrap-table table {
  table-layout: fixed; }

.react-bootstrap-table th.sortable {
  cursor: pointer; }

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc; }

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc; }

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px; }

.react-bootstrap-table th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4; }

.react-bootstrap-table th .order-4:after {
  content: "\2193";
  opacity: 0.4; }

.react-bootstrap-table th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191"; }

.react-bootstrap-table th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4; }

.react-bootstrap-table th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4; }

.react-bootstrap-table th .caret-4-desc:after {
  content: "\2193"; }

.react-bootstrap-table th[data-row-selection] {
  width: 30px; }

.react-bootstrap-table th > .selection-input-4,
.react-bootstrap-table td > .selection-input-4 {
  margin: -4px; }

.react-bootstrap-table td.react-bs-table-no-data {
  text-align: center; }

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceIn,
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceOut {
  -webkit-animation-duration: .75s;
          animation-duration: .75s; }

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.shake {
  -webkit-animation-duration: .3s;
          animation-duration: .3s; }

@-webkit-keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0); }
  10%, 50%, 90% {
    transform: translate3d(-10px, 0, 0); }
  30%, 70% {
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0); }
  10%, 50%, 90% {
    transform: translate3d(-10px, 0, 0); }
  30%, 70% {
    transform: translate3d(10px, 0, 0); } }

.react-bootstrap-table td.react-bootstrap-table-editing-cell .shake {
  -webkit-animation-name: shake;
          animation-name: shake; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn; }

@-webkit-keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceOut {
  -webkit-animation-name: bounceOut;
          animation-name: bounceOut; }

.react-bootstrap-table .reset-expansion-style {
  padding: 0; }

.react-bootstrap-table .row-expansion-style {
  padding: 8px; }

.react-bootstrap-table .row-expand-slide-appear {
  max-height: 0;
  overflow: hidden; }

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: max-height 3s linear; }

.react-bootstrap-table .row-expand-slide-exit {
  max-height: 1000px; }

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95); }

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rdrDateDisplay {
  display: flex;
  justify-content: space-between; }

.rdrDateDisplayItem {
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit; }

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.833em; }

.rdrDateDisplayItem input {
  text-align: inherit; }

.rdrDateDisplayItem input:disabled {
  cursor: default; }

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between; }

.rdrMonthAndYearPickers {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center; }

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none; }

.rdrMonths {
  display: flex; }

.rdrMonthsVertical {
  flex-direction: column; }

.rdrMonthsHorizontal > div > div > div {
  display: flex;
  flex-direction: row; }

.rdrMonth {
  width: 27.667em; }

.rdrWeekDays {
  display: flex; }

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center; }

.rdrDays {
  display: flex;
  flex-wrap: wrap; }

.rdrInfiniteMonths {
  overflow: auto; }

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rdrDateInput {
  position: relative; }

.rdrDateInput input {
  outline: none; }

.rdrDateInput .rdrWarning {
  position: absolute;
  font-size: 1.6em;
  line-height: 1.6em;
  top: 0;
  right: .25em;
  color: #FF0000; }

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer; }

.rdrDayNumber {
  display: block;
  position: relative; }

.rdrDayNumber span {
  color: #1d2429; }

.rdrDayDisabled {
  cursor: not-allowed; }

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important; } }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  pointer-events: none; }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none; }

.rdrDateRangePickerWrapper {
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rdrStaticRanges {
  display: flex;
  flex-direction: column; }

.rdrStaticRange {
  font-size: inherit; }

.rdrInputRange {
  display: flex; }

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px; }

.rdrDateDisplayWrapper {
  background-color: #eff2f7; }

.rdrDateDisplay {
  margin: 0.833em; }

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent; }

.rdrDateDisplayItem input {
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095; }

.rdrDateDisplayItemActive {
  border-color: currentColor; }

.rdrDateDisplayItemActive input {
  color: #7d888d; }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px; }

.rdrMonthAndYearPickers {
  font-weight: 600; }

.rdrMonthAndYearPickers select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  outline: 0;
  color: #3e484f;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
  background-position: right 8px center;
  cursor: pointer;
  text-align: center; }

.rdrMonthAndYearPickers select:hover {
  background-color: rgba(0, 0, 0, 0.07); }

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px; }

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7; }

.rdrNextPrevButton:hover {
  background: #E1E7F0; }

.rdrNextPrevButton i {
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  margin: auto;
  transform: translate(-3px, 0px); }

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: transparent #34495e transparent transparent;
  transform: translate(-3px, 0px); }

.rdrNextButton i {
  margin: 0 0 0 7px;
  border-width: 4px 4px 4px 6px;
  border-color: transparent transparent transparent #34495e;
  transform: translate(3px, 0px); }

.rdrWeekDays {
  padding: 0 0.833em; }

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em; }

.rdrMonth .rdrWeekDays {
  padding: 0; }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none; }

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: #849095; }

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429; }

.rdrDay:focus {
  outline: 0; }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

.rdrDayToday .rdrDayNumber span {
  font-weight: 500; }

.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #3d91ff; }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff; }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: rgba(255, 255, 255, 0.85); }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px; }

.rdrSelected {
  left: 2px;
  right: 2px; }

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px; }

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px; }

.rdrSelected {
  border-radius: 1.042em; }

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px; }

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px; }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0px; }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0px; }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1; }

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px; }

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px; }

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px; }

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff; }

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600; }

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff; }

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7; }

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left; }

.rdrInputRanges {
  padding: 10px 0; }

.rdrInputRange {
  align-items: center;
  padding: 5px 20px; }

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px #dee7eb;
  margin-right: 10px;
  color: #6c767a; }

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
  border-color: #b4bfc4;
  outline: 0;
  color: #333; }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent; }

.rdrDayPassive {
  pointer-events: none; }

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0; }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview {
  display: none; }

.rdrDayDisabled {
  background-color: #f8f8f8; }

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf; }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  -webkit-filter: grayscale(100%) opacity(60%);
  filter: grayscale(100%) opacity(60%); }

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em; }

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-clock {
  display: block;
  position: relative;
}
.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  box-sizing: border-box;
}
.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid black;
  border-radius: 50%;
}
.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__hand__body {
  position: absolute;
  background-color: black;
  transform: translateX(-50%);
}
.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__mark__body {
  position: absolute;
  background-color: black;
  transform: translateX(-50%);
}
.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}
.react-clock__second-hand__body {
  background-color: red;
}

.react-datetime-picker {
  display: inline-flex;
  position: relative;
}
.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  box-sizing: border-box;
}
.react-datetime-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
}
.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-datetime-picker__button:enabled {
  cursor: pointer;
}
.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #0078d7;
}
.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}
.react-datetime-picker__button svg {
  display: inherit;
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
  display: none;
}
.react-datetime-picker__calendar {
  width: 350px;
  max-width: 100vw;
}
.react-datetime-picker__calendar .react-calendar {
  border-width: thin;
}
.react-datetime-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
}

